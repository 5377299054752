import React from 'react';
import './support.css';

const Support = () => {
  return (
    <div className="support-container">
      <h2>Support</h2>
     
      <iframe title="Support" src="https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/290b6c7e-1898-4d44-bd46-1d5bd71a60f1" referrerpolicy="origin" sandbox="allow-forms allow-popups allow-scripts allow-same-origin" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  );
};

export default Support;