import { Link } from 'react-router-dom';
import './App.css';
import logo from './logo.png'

const imagePath = "./assets/images";

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="container">
          <div>
            <h2>Quick Record</h2>
            <div className="feature-content">
              <img src={require(`${imagePath}/watch_quick_record.png`)} className="feature-content__image" alt="quick record" />
              <p>Quick access to your most frequently logged tasks. Start and stop tasks with ease. Switch between the standard view and tiled view, which displays more tasks at a glance—perfect for users who have a lot of tasks to keep track of.</p>
            </div>
          </div>
          <div>
            <h2>Projects</h2>
            <div className="feature-content">
              <img src={require(`${imagePath}/watch_projects.png`)} className="feature-content__image" alt="quick record" />
              <p>Organize your life into separate projects and assign relevant tasks to each one. Customize your experience by color-coding your projects.</p>
            </div>
          </div>
          <div>
            <h2>Calendar</h2>
            <div className="feature-content">
              <img src={require(`${imagePath}/watch_calendar.png`)} className="feature-content__image" alt="quick record" />
              <p>Gain insights into how you spend your days and identify patterns.</p>
            </div>
          </div>
          <div>
            <h2>Widgets</h2>
            <div className="feature-content">
              <img src={require(`${imagePath}/watch_widget.png`)} className="feature-content__image" alt="quick record" />
              <p>Your current task is always visible on-screen. Switch to another task with just a few taps.</p>
            </div>
          </div>
          <div className="center-flex">
            <button className="modern-btn" onClick={() => { window.open('https://forms.gle/ogNWMWqvjMgXALFJ7', '_blank'); }}>
              Sign Up for Beta Testing
            </button>
          </div>
          <div>
            <h2>About Us</h2>
            <p>Ondori 雄鶏 (おんどり), the Japanese word for rooster, is known for its crowing at dawn, a behavior driven by its internal clock rather than the rising sun. Our aspiration is to grow mindfulness around our use of time, and the very first step in this journey is gaining a deeper understanding of how we spend our time.</p>
            <p>With Ondori, we can log various tasks with a few taps on our watches, from spending quality time with our kids to workouts, showers, and even bathroom breaks. This has given us a clearer understanding of how we spend our time throughout the day and how much of it actually goes into the things that truly matter to us. </p>
            <p>We're a married couple with two young children, both of us juggling full-time jobs. Despite our busy schedules, we often realize that there's still a lot we want to do by the end of the day, leaving us to wonder where all the time has gone. That's why we created Ondori—an easy time tracking app designed for busy lives on the move.</p>
          </div>
        </div>
      </div>
      <footer>
        <Link to="Support">
          Support
        </Link> 
        <Link to="Terms">
          Terms
        </Link>
        <Link to="Privacy">
          Privacy
        </Link>
      </footer>
    </div>
  );
}

export default App;
